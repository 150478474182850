.tooltipWrapper {
    position: relative;
    display: inline-block;
  }
  
  .tooltipTrigger {
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltip {
    position: absolute;
    min-width: 62px;
    height: 24px;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -180%);
    border: 1px solid rgb(0, 0, 0);
    border-style: solid;
    /* border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid black; */
    color: #0c0c0c;
    font-size: 14px;
    border-radius: 20px;
    opacity: 0.9;
    white-space: nowrap;
    text-align: center;
  }

  .tooltip:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid black;
    left: 45%;
    top: 8px;
    transform: translateY(140%) rotate(-90deg);
  }

  .tooltipContainer {
    position: absolute;
    min-width: 80px;
    height: 24px;
    top: 80%;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%, -200%);
    border: 1px solid rgb(0, 0, 0);
    border-style: solid;
    color: #0c0c0c;
    font-size: 14px;
    border-radius: 20px;
    opacity: 0.9;
    white-space: nowrap;
    text-align: center;
    margin-bottom: '10px';
  }

  /* .tooltip:before {
    content: '';
    position: absolute;
    border-bottom: solid 48px transparent;
    border-top: solid 48px white;
    border-left: solid 48px transparent;
    border-right: solid 48px transparent;
  } */

  /* .toolTipText {
    margin-bottom: 3px !important;
  } */
  
  
